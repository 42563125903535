
import Vue from 'vue'
import { INewsCategory } from 'types/DTO/news'
import breakpoints from '~/mixins/breakpoints'

export default Vue.extend({
  name: 'RPHArticlesGrid',
  components: {
    RPHArticleCard: () => import('rph/molecules/RPHArticleCard/index.vue'),
  },

  mixins: [breakpoints],
  computed: {
    articles(): any {
      const mainPageElementAmount =
        this.screenWidth <= 925 && this.screenWidth > 600 ? 3 : this.screenWidth <= 600 ? 4 : 4
      const pageElementAmount = this.screenWidth <= 925 && this.screenWidth > 600 ? 3 : 4
      const articlesList = this.$store.getters['rph-news/articles']
      return this.$route.path === '/'
        ? articlesList.slice(0, mainPageElementAmount)
        : articlesList.slice(0, pageElementAmount)
    },
    categories(): INewsCategory[] {
      return this.$store.getters['rph-news/categories']
    },
  },
})
